
<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getMarketplaceRatesLoading === true}">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton v-if="isAdmin" :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['marketplaceReviews_sync'])"></div>
        <MainButton class="btn-fit-content ml-2"
                    v-if="(!isAdmin && userShopId > 0)"
                    v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceRatesBtn}"
                    :value="$t('common_AddNew.localization_value.value')"
                    :ico="true"
                    @click.native="isModalMarketplaceRates = true"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
      </div>
    </div>


    <MarketplaceRatesPopup
        v-if="isModalMarketplaceRates"
        :shopId="userShopId"
        :isEdit="false"
        @close="isModalMarketplaceRates = false"
        @reload="$emit('reload')"
    />
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import MarketplaceRatesPopup
    from "@/components/modules/MarketplaceRatesModule/popups/MarketplaceRatesPopup/MarketplaceRatesPopup";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


  export default {
    name: "MarketplaceRatesHead",
    components: {
      MarketplaceRatesPopup,
      MainButton,
      PlusIconSVG,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      userShopId: Number,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,

        isModalMarketplaceRates: false,
      }
    },



    methods: {

    }
  }
</script>

<style scoped>

.likeTooltipBtn{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-20px, -8px);
}

.likeTooltipBtn__item{
  padding: 0;
  width: 20px;
  margin-top: -18px;
  margin-left: 4px;
}

  @media (max-width: 1220px){
    .print-btn__btn {
      padding-right: 16px;
      padding-left: 0;
    }
  }

@media (max-width: 991px) {
  .likeTooltipBtn {
    position: absolute;
    top: -8px;
    right: 0;
    transform: none;
  }
}
</style>
