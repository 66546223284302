<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['marketplaceRates_edit', 'marketplaceRates_create',])"></div>
        <div v-if="isEdit">{{ $t('marketplaceRates_edit.localization_value.value') }}</div>
        <div v-else>{{ $t('marketplaceRates_create.localization_value.value') }}</div>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
          <div class="custom-col custom-col--100 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_country'])"></div>
            <MultiSelect
                :options="options"
                v-bind:class="{'ui-no-valid': MR.validation.countries}"
                :error="MR.validation.countries"
                :errorTxt="$t(`${MR.validationTranslate.countries}.localization_value.value`)"
                :label="$t('marketplaceRates_country.localization_value.value')"
                :optionsLabel="'label'"
                :clearable="false"
                :selected="MR.data.countries"
                @change="changeCountry"
            />
          </div>

        </div>

        <div class="custom-row">
          <div class=" custom-col custom-col--50 custom-col--sm-50 custom-padding"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_price'])"></div>
            <InputSum
                :label="$t('marketplaceRates_price.localization_value.value')"
                v-bind:class="{'ui-no-valid': MR.validation.price}"
                :error="MR.validation.price"
                :errorTxt="$t(`${MR.validationTranslate.price}.localization_value.value`)"
                :placeholder="'0'"
                :icoType="'dollar'"
                v-model="MR.data.price"
            />
          </div>
          <div class=" custom-col custom-col--50 custom-col--sm-50 custom-padding"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_additionalPrice'])"></div>
            <InputSum
                :label="$t('marketplaceRates_additionalPrice.localization_value.value')"
                v-bind:class="{'ui-no-valid': MR.validation.additionalItemPrice}"
                :error="MR.validation.additionalItemPrice"
                :errorTxt="$t(`${MR.validationTranslate.additionalItemPrice}.localization_value.value`)"
                :placeholder="'0'"
                :icoType="'dollar'"
                v-model="MR.data.additionalItemPrice"
            />
          </div>
          <div class="custom-col custom-col--50  custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_weightFrom'])"></div>
            <DefaultInput
                :label="$t('marketplaceRates_weightFrom.localization_value.value')"
                v-bind:class="{'ui-no-valid': MR.validation.weightFrom}"
                :error="MR.validation.weightFrom"
                :errorTxt="$t(`${MR.validationTranslate.weightFrom}.localization_value.value`)"
                :type="'text'"
                v-model="MR.data.weightFrom"
            />
          </div>
          <div class="custom-col custom-col--50  custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_weightTo'])"></div>
            <DefaultInput
                :label="$t('marketplaceRates_weightTo.localization_value.value')"
                v-bind:class="{'ui-no-valid': MR.validation.weightTo}"
                :error="MR.validation.weightTo"
                :errorTxt="$t(`${MR.validationTranslate.weightTo}.localization_value.value`)"
                :type="'text'"
                v-model="MR.data.weightTo"
            />
          </div>
          <div class="custom-col custom-col--100  custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_deliveryTime'])"></div>
            <TextareaDefault
                :label="$t('marketplaceRates_deliveryTime.localization_value.value')"
                v-bind:class="{'ui-no-valid': MR.validation.deliveryTime}"
                :error="MR.validation.deliveryTime"
                :errorTxt="$t(`${MR.validationTranslate.deliveryTime}.localization_value.value`)"
                :type="'text'"
                :maxlength="20"
                v-model="MR.data.deliveryTime"
            />
          </div>
          <div class=" custom-col custom-col--66 custom-col--sm-50 custom-padding"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceRates_isFreeShip'])"></div>
            <DefaultCheckbox
                :label="$t('marketplaceRates_isFreeShip.localization_value.value')"
                :value="MR.data.isFreeShipping"
                @change.native="MR.data.isFreeShipping = !MR.data.isFreeShipping"
            />
          </div>
          <div class="custom-col custom-col--66  custom-col--sm-100">
            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['marketplaceRates_superset','marketplaceRates_supersetStandard','marketplaceRates_supersetExpress'])"></div>
              {{$t('marketplaceRates_superset.localization_value.value')}}
            </div>
            <RadioDefault
                :label="$t('marketplaceRates_supersetStandard.localization_value.value')"
                :name="'nameRadio'"
                class="mb-2"
                :value="MR.data.marketplaceTableRateSupersetId === MR.data.supersetTypes.standard"
                @change.native="MR.setMarketplaceTableRateSupersetId(MR.data.supersetTypes.standard)"
            />
            <RadioDefault
                :label="$t('marketplaceRates_supersetExpress.localization_value.value')"
                :name="'nameRadio'"
                class="mb-2"
                :value="MR.data.marketplaceTableRateSupersetId === MR.data.supersetTypes.express"
                @change.native="MR.setMarketplaceTableRateSupersetId(MR.data.supersetTypes.express)"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'common_update',
        ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="isEdit ? $t('common_update.localization_value.value') : $t('common_create.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="update"
            v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceRatesBtn}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import {MarketplaceRates} from "@/components/modules/MarketplaceRatesModule/models/MarketplaceRates";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
import {filterCountriesUSA} from "@/services/filterCountries";
import MultiSelect from "@/components/UI/selectiones/MultiSelect/MultiSelect";

export default {
  name: "MarketplaceRatesPopup",
  components: {
    MultiSelect,
    TextareaDefault,
    RadioDefault,
    DefaultCheckbox,
    InputSum,
    DefaultInput,
    Modal,
    MainButton,
  },

  props: {
    rateId: {
      type: Number,
      default: null,
    },
    shopId: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // status: '',
      // MARKETPLACE_REVIEWS_STATUSES_ARRAY: MARKETPLACE_REVIEWS_STATUSES_ARRAY,

      MR: new MarketplaceRates(),

      options: [],
    }
  },

  mounted() {
    this.$store.dispatch('fetchCountries', '?filter[shippingRatesCountries]=1').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      filterCountriesUSA(countries)
      this.options = countries
      this.options.unshift({
        id: -1,
        name: 'All Countries',
        label: 'All Countries'
      })
    })

    if (this.isEdit) {
      this.setMarketplaceRates()
    } else {
      this.MR.setShop({id: Number(this.shopId)})
    }
  },

  methods: {

    setMarketplaceRates() {
      this.MR.data.Order.setId(this.rateId)

      this.$store.dispatch('getMarketplaceRates', this.MR.data.Order.getId()).then(response => {
        let item = this.getRespData(response)

        this.MR.setItem(item)
      })
    },

    changeCountry(val) {
      let foundAllCounties = this._.find(val, {id: -1})
      if(foundAllCounties) {
        this.MR.data.countries = this._.cloneDeep(this.options)
        this.MR.data.countries.shift()
        return
      }
      this.MR.data.countries = val
    },


    update() {
      if(!this.MR.validationItem()) return

      let data = this.MR.prepareSave()
      let dispatchType = 'createMarketplaceRates'

      if(this.isEdit) {
        dispatchType = 'updateMarketplaceRates'
        data = {
          id: this.MR.data.Order.getId(),
          data: data
        }
      }

      this.$store.dispatch(dispatchType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordCreated')

            this.$emit('reload')
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })


    },

    // changeStatus(val) {
    //   this.status = val
    // },

    // getCurrentStatus() {
    //   this.status = MARKETPLACE_REVIEWS_STATUSES_ARRAY.find(item => {
    //     return item.value === this.currentStatus
    //   })
    //   console.log(this.status);
    // },
  }

}

</script>

<style scoped lang="scss">

</style>
