import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";

// const _ = require('lodash');


export function MarketplaceRates() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    shop: null,
    countries: null,
    price: 0,
    additionalItemPrice: null,
    isFreeShipping: false,
    weightFrom: null,
    weightTo: null,
    deliveryTime: null,
    marketplaceTableRateSupersetId: 1,

    supersetTypes: {
      standard: 1,
      express: 2,
    }
  }

  this.validation = {
    shop: false,
    countries: false,
    price: false,
    additionalItemPrice: false,
    isFreeShipping: false,
    weightFrom: false,
    weightTo: false,
    deliveryTime: false,
    marketplaceTableRateSupersetId: false,
  }

  this.validationTranslate = {
    shop: '',
    countries: '',
    price: '',
    additionalItemPrice: '',
    isFreeShipping: '',
    weightFrom: '',
    weightTo: '',
    deliveryTime: '',
    marketplaceTableRateSupersetId: '',
  }

  this.validationTxt = {
    shop: false,
    countries: false,
    price: false,
    additionalItemPrice: false,
    isFreeShipping: false,
    weightFrom: false,
    weightTo: false,
    deliveryTime: false,
    marketplaceTableRateSupersetId: false,
  }

  /**
   * Getters
   */
  
  this.getShop = () => {
    return this.data.shop
  }
  this.getCountries = () => {
    return this.data.countries
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getAdditionalItemPrice = () => {
    return this.data.additionalItemPrice
  }
  this.getIsFreeShipping = () => {
    return this.data.isFreeShipping
  }
  this.getWeightFrom = () => {
    return this.data.weightFrom
  }
  this.getWeightTo = () => {
    return this.data.weightTo
  }
  this.getDeliveryTime = () => {
    return this.data.deliveryTime
  }
  this.getMarketplaceTableRateSupersetId = () => {
    return this.data.marketplaceTableRateSupersetId
  }


  /**
   * Setters
   */
 
  this.setShop = (val) => {
    this.data.shop = val
  }
  this.setCountries = (val) => {
    this.data.countries = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setAdditionalItemPrice = (val) => {
    this.data.additionalItemPrice = val
  }
  this.setIsFreeShipping = (val) => {
    this.data.isFreeShipping = val
  }
  this.setWeightFrom = (val) => {
    this.data.weightFrom = val
  }
  this.setWeightTo = (val) => {
    this.data.weightTo = val
  }
  this.setDeliveryTime = (val) => {
    this.data.deliveryTime = val
  }
  this.setMarketplaceTableRateSupersetId = (val) => {
    this.data.marketplaceTableRateSupersetId = val
  }
  
}


/**
 * Global Setters
 */

MarketplaceRates.prototype.setItem = function (item) {
  this.setShop(item.shop)
  this.setCountries(
    item.countryEntities.map(country => {
      let newCountry = country
      newCountry.label = country.name
      return newCountry
    })
  )
  this.setPrice(item.price)
  this.setAdditionalItemPrice(item.additional_item_price)
  this.setIsFreeShipping(item.is_free_shipping)
  this.setWeightFrom(item.weight_from)
  this.setWeightTo(item.weight_to)
  this.setDeliveryTime(item.delivery_time)
  this.setMarketplaceTableRateSupersetId(item.marketplace_tablerate_superset_id)
}


/**
 * Functions
 */


// MarketplaceProducts.prototype.addProductForCells = function (product = false, cells = false) {
//   let productForCell = new ProductForCell(product)
//   if(cells.length > 0){
//     productForCell.setCellsByType(cells)
//   }
//   this.data.productsForCellArray.push(productForCell)
//   return this.data.productsForCellArray.length - 1
// }

// MarketplaceProducts.prototype.setFiles = function (val) {
//   this.data.Documents.pushDownloadFiles(val)
// }

// MarketplaceProducts.prototype.removeFile = function (id) {
//   let i = -1
//   _.findIndex(this.data.Documents.getDownloadFiles(), function (item, index) {
//     if (item[id] !== undefined) i = index
//   });
//
//   this.data.Documents.removeDownloadFile(i)
// }

// MarketplaceRates.prototype.productsDataGeneration = function (userId, storageId) {
//   let item = _.first(this.data.productsForCellArray)
//
//   let product = item.data.ProformProduct.product
//   let productId = product.productObject?.id
//
//   if(!productId) return
//
//
//   let itemData = {
//     "storage_id": storageId ? storageId : 1,
//     "inventory_type": INVENTORY_TYPES.MOVE,
//     "type_id": WAREHOUSE_STORAGE_DOCUMENT_TYPES.TYPE_INVENTORY,
//     "user_id": userId,
//     "status": 'completed',
//     "comment": null,
//     "costs": null,
//     "requested_date": null,
//     "products": [],
//   }
//
//   let cells = [],
//     userProductCellArray = item.data.UserProductCellArray,
//     userMixedProductCellArray = item.data.UserMixedProductCellArray,
//     userCellArray = item.data.UserCellArray,
//     mixedCellArray = item.data.MixedCellArray,
//     freeCellArray = item.data.FreeCellArray
//
//   cells = [...cells, ...this.getCellsFromArray(userProductCellArray, productId, true)]
//   cells = [...cells, ...this.getCellsFromArray(userMixedProductCellArray, productId, true)]
//   cells = [...cells, ...this.getCellsFromArray(userCellArray, productId)]
//   cells = [...cells, ...this.getCellsFromArray(mixedCellArray, productId)]
//   cells = [...cells, ...this.getCellsFromArray(freeCellArray, productId)]
//
//   cells.push({
//     "product_id": productId,
//     "cell_id": null,
//     "quantity": parseInt(item.data.withoutCellEndCounts),
//     "damage": false,
//   })
//
//   itemData["products"] = cells
//
//   return itemData
// }

// MarketplaceRates.prototype.getCellsFromArray = function (array, productId, allItems = false) {
//   let cells = []
//   array.map(itemCell => {
//     if(itemCell.data.cellCount > 0 || allItems){
//       cells.push({
//         "product_id": productId,
//         "cell_id": itemCell.data.cellSelected.id,
//         "quantity": parseInt(itemCell.data.cellCount),
//         "damage": false,
//       })
//     }
//   })
//
//   return cells
// }

/**
 * Validations
 */

MarketplaceRates.prototype.validationItem = function() {
  let validationItems = {
    countries: this.getCountries(),
    price: this.getPrice(),
    additionalItemPrice: this.getAdditionalItemPrice(),
    weightFrom: this.getWeightFrom(),
    weightTo: this.getWeightTo(),
    deliveryTime: this.getDeliveryTime(),
  }

  let validationOptions = {
    countries: {type: ['empty-array']},
    price: {type: ['numeric', 'not-zero', 'empty']},
    additionalItemPrice: {type: ['numeric', 'empty']},
    weightFrom: {type: ['numeric', 'not-zero', 'empty']},
    weightTo: {type: ['numeric', 'not-zero', 'empty']},
    deliveryTime: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

/**
 * Prepare Data
 */

MarketplaceRates.prototype.prepareSave = function () {

  let prepareData = {
    'shop_id': this.getShop()?.id,
    'countries': this.getCountries().map(country => {return country.code_iso_2}),
    'price': this.getPrice(),
    'additional_item_price': this.getAdditionalItemPrice(),
    'is_free_shipping': this.getIsFreeShipping(),
    'weight_from': this.getWeightFrom(),
    'weight_to': this.getWeightTo(),
    'delivery_time': this.getDeliveryTime(),
    'marketplace_tablerate_superset_id': this.getMarketplaceTableRateSupersetId(),
  }

  return prepareData

}
