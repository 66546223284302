<template>
  <div class="fragment">
    <template v-if="$store.getters.getMarketplaceRates.length > 0 && !$store.getters.getMarketplaceRatesLoading">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_id',
          'marketplaceRates_shop',
          'marketplaceRates_price',
          'marketplaceRates_additionalPrice',
          'marketplaceRates_country',
          'marketplaceRates_isFreeShip',
          'marketplaceRates_weightFrom',
          'marketplaceRates_weightTo',
          'marketplaceRates_deliveryTime',
          'marketplaceRates_superset',
          'common_manage',
        ])"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('common_id.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_shop.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_price.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_additionalPrice.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_country.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_isFreeShip.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_weightFrom.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_weightTo.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_deliveryTime.localization_value.value')}}</th>
            <th>{{$t('marketplaceRates_superset.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getMarketplaceRates"
              :key="index">
            <td>
                #{{item.id}}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'shop.shop_name'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'price'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'additional_item_price'"
              />
            </td>
            <td>
<!--              {{getCountries(item)}}-->
              <TooltipTextHelper
                  :fullTextWidth="180"
                  :text="getCountries(item)"
                  :paragraph="getCountries(item)"
              />
            </td>
            <td>
              <DefaultCheckbox
                  :label="''"
                  :value="item.is_free_shipping === 1"
                  :disabled="true"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'weight_from'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'weight_to'"
              />
            </td>

            <td class="white-space-line">
              <div class="table-row">
                <div class="d-flex align-center">
                  <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
                  >
                    <div class="ellipsis">{{ item.delivery_time }}</div>
                    <template slot="popover">
                      <p>{{ item.delivery_time }}</p>
                    </template>
                  </v-popover>
                </div>
              </div>
            </td>

            <td>
              {{item.marketplace_tablerate_superset_id === 1 ? 'Standard' : 'Express'}}
            </td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            @click.native="edit(item.id)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="deleteRate(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getMarketplaceRates"
                 :key="index"
            >
              <MarketplaceRatesTableMobile
                      :item="item"
                      @edit="edit(item.id)"
                      @deleteRate="deleteRate(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getMarketplaceRatesCommonList.next_page_url !== null && $store.getters.getMarketplaceRates.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextMarketplaceRatesPage}"
                    :count="$store.getters.getMarketplaceRatesCommonList.total - $store.getters.getMarketplaceRatesForPage * $store.getters.getMarketplaceRatesCommonList.current_page < $store.getters.getMarketplaceRatesForPage ?
                  $store.getters.getMarketplaceRatesCommonList.total - $store.getters.getMarketplaceRatesForPage * $store.getters.getMarketplaceRatesCommonList.current_page:
                  $store.getters.getMarketplaceRatesForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="(userShopId > 0 || isAdmin) && $store.getters.getMarketplaceRatesLoading === false && $store.getters.getMarketplaceRates.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <MarketplaceRatesPopup
        v-if="isModalMarketplaceRates"
        :rateId="orderId"
        :shopId="userShopId"
        :isEdit="true"
        @close="isModalMarketplaceRates = false"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import MarketplaceRatesTableMobile from "./MarketplaceRatesTableMobile/MarketplaceRatesTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import MarketplaceRatesPopup from "../../../../popups/MarketplaceRatesPopup/MarketplaceRatesPopup";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";


  export default {
    name: "MarketplaceRatesTable",

    components: {
      TooltipTextHelper,
      DefaultCheckbox,
      MarketplaceRatesPopup,
      ValueHelper,
      NoResult,
      MarketplaceRatesTableMobile,
      // MainButton,
      // DefaultInput,
      ShowMore,
      LinkButton,
      ManagerButton,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      userShopId: Number,
    },

    mixins: [
      mixinDetictingMobile,
    ],

    computed: {
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        isEdit: false,
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalMarketplaceRates: false,
      }
    },

    methods: {

      getCountries(item) {
        return item.countries.join(',')
      },

      deleteRate(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteMarketplaceRates', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      edit(id) {
        this.orderId = id
        this.isModalMarketplaceRates = true
      }
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 186px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }



</style>
