<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['marketplaceReviews_shop',])"></div>
        <DefaultSelect
            :options="options"
            :label="$t('marketplaceReviews_shop.localization_value.value')"
            :optionsLabel="'shop_name'"
            :selected="shopId ? _.find(options, {id: parseInt(shopId)}) : null"
            @change="changeShopFilter"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {SHOP_TYPES} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "MarketplaceRatesFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc],

    mounted() {
      this.$store.dispatch(
          'fetchShops',
          `?filter[type]=${SHOP_TYPES.ukrainiantreasures.id}&filter[shopStatus]=valid`
      ).then(response => {
        this.options = this.getRespPaginateData(response)
      })
    },

    data(){
      return {

        shopId: this.filterGetParams.shopId ? this.filterGetParams.shopId : '',

        filterCounts: [
          'shopId',
        ],

        options: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.shopId = newVal.shopId ? newVal.shopId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeShopFilter(val) {
        this.shopId = val.id
      },
    },
  }
</script>

<style scoped>

</style>
