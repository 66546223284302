<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number">
        #{{item.id}}
      </div>
      <div class="table-card__item-head-row">
<!--        <div class="table-card__item-head-txt">-->
<!--          {{ item.created_at | moment("DD MMM, YYYY") }}-->
<!--        </div>-->
      </div>
      <!--<div class="table-card__item-head-label">-->
        <!--<div class="table-card__label-circle table-card__item-head-label-i" style="background-color: #9B51E0;">-->
          <!--L-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
<!--        <div class="table-card__item-row">-->
<!--          <div class="table-card__item-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('etsyListing_customer.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              {{item.upc_code}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('etsyListing_listingId.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              {{item.sku_code}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceReviews_shop.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'shop.shop_name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'price'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_additionalPrice.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'additional_item_price'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_country.localization_value.value')}}
            </div>
            <div class="table-card__item-info" style="word-break: break-all;">
              {{getCountries(item)}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_isFreeShip.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <DefaultCheckbox
                  :label="''"
                  :value="item.is_free_shipping === 1"
                  :disabled="true"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_weightFrom.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'weight_from'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_weightTo.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'weight_to'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_deliveryTime.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'delivery_time'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('marketplaceRates_superset.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.marketplace_tablerate_superset_id === 1 ? 'Standard' : 'Express'}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                @click.native="$emit('edit')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('deleteRate')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "MarketplaceRatesTableMobile",

    components: {DefaultCheckbox, ValueHelper, LinkButton},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {
      getCountries(item) {
        return item.countries.join(',')
      },
    },

  }
</script>

<style scoped>
.table-card .ellipsis {
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}
</style>
