<template>
  <div class="fragment">
    <MarketplaceRatesTableUser
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :userShopId="userShopId"
        @changeSort="data => changeSort(data)"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @reload="reload"
    />

  </div>
</template>

<script>
  import MarketplaceRatesTableUser from "./MarketplaceRatesTableUser/MarketplaceRatesTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "MarketplaceRatesTable",

    components: {
      MarketplaceRatesTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getMarketplaceRatesForPage,
        page: 1,
        onePage: 1,

        countFilterParams: 0,
        filterGetParams: {},
        filterShopId: '',
        userShopId: -1,
      }
    },

    mounted() {
      if (this.isAdmin) {
        this.filter()
      }
      else {
        this.getUserShop().then(() => {
          this.filter()
        })
      }
    },

    methods: {
      async getUserShop() {
        return this.$store.dispatch('fetchShops', '?filter[shopStatus]=valid&filter[type]=12').then(response => {
          this.userShopId = this._.first(this.getRespPaginateData(response))?.id || -1
        })
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          shopId: this.$route.query.shopId,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextMarketplaceRatesPage', true)

        // save filter params in store
        this.$store.commit('setMarketplaceRatesFilter', window.location.search)

        this.$store.dispatch('fetchMarketplaceRates', url).then(() => {
          // skip get next page
          this.$store.commit('setNextMarketplaceRatesPage', false)
        })

        this.checkCountFilter([])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if (!this.isAdmin) {
          myQuery.where('byShopId', this.userShopId)
        }

        let params = {}

        if (this.isAdmin) {
          params['filterShopId'] = 'byShopId'
        }

        this.generateFilterQueryParams(
          myQuery, params,
        )

        // If generate on export file
        if(exportIds) {
          myQuery.whereIn('ProductId', exportIds)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

    },

    beforeDestroy() {
      this.$store.commit('setMarketplaceRates',{marketplaceRates: []})
      this.$store.commit('setMarketplaceRatesCommonList',{marketplaceRatesCommonList: []})
    },


  }
</script>

<style scoped>

</style>
